var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "facebook-share-section"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "content"
  }, [_c('p', [_vm._v("شارك صفحة مؤسسة التميز التنموية واحصل على العديد من النقاط")]), _c('button', {
    on: {
      "click": _vm.onShareFacebook
    }
  }, [_vm._v("مشاركة الصفحة")])])]), _vm._m(0)]), _c('share-popup', {
    attrs: {
      "type": "FACEBOOK_PAGE",
      "entity": _vm.entity
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "image"
  }, [_c('img', {
    attrs: {
      "src": require("../../../../../public/assets/images/website/zadi/facebook-bg.png")
    }
  })])]);
}]

export { render, staticRenderFns }