<template>
  <div ref="magazineSwiper" class="magazine-swiper">
    <div class="swiper-wrapper">
      <template v-if="!isLoadingMagazines">
        <div class="swiper-slide" v-for="magazine in recentMagazines" :key="magazine.id">
          <magazine-card :magazine="magazine" @share-magazine="onShareMagazine" />
        </div>
      </template>
      <template v-else>
        <cover-loader size="75px" />
      </template>
    </div>
    <share-popup type="MAGAZINE" :entity="magazine" />
  </div>
</template>

<script>
import MagazineCard from "../magazines/MagazineCard.vue";
import SharePopup from "../shared/SharePopup.vue";
import CoverLoader from "../shared/CoverLoader.vue";
import Swiper, { Autoplay } from "swiper";
import "swiper/swiper-bundle.css";

export default {
  components: { MagazineCard, SharePopup, CoverLoader },
  props: {
    recentMagazines: {
      type: Array,
      default: () => null,
    },
    isLoadingMagazines: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      magazine: null
    }
  },
  methods: {
    onShareMagazine(magazine) {
      this.magazine = magazine;
      this.$nextTick(() => {
        $(`#share-popup-magazine`).modal('show');
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      new Swiper(this.$refs.magazineSwiper, {
        modules: [Autoplay],
        loop: false,
        speed: 1000,
        grabCursor: true,
        centeredSlides: false,
        // setWrapperSize: true,
        // noSwiping: false,
        // swiper-no-swiping
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
        breakpoints: {
          200: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          500: {
            slidesPerView: 1.6,
            spaceBetween: 10,
          },
          650: {
            slidesPerView: 2.2,
            spaceBetween: 10,
          },
          790: {
            slidesPerView: 2.7,
            spaceBetween: 10,
          },
          992: {
            slidesPerView: 3.5,
            spaceBetween: 15,
          },
          1250: {
            slidesPerView: 4.2,
            spaceBetween: 15,
          },
        },
      });
    });
  },
};
</script>

<style scoped>
.magazine-swiper {
  overflow: hidden;
  background: white;
  padding-bottom: 15px;
}
</style>