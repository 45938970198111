var render = function () {
  var _vm$recentBook;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "recent-book-section"
  }, [_c('div', {
    staticClass: "summry"
  }, [_c('home-section-header', {
    attrs: {
      "title": "كنوز زادي",
      "route-name": "ZadiBooks"
    }
  }), !_vm.isLoadingRecentBook ? [_c('book-summary', {
    attrs: {
      "recent-book": _vm.recentBook
    },
    on: {
      "share-recent-book": _vm.onShareRecentBook
    }
  })] : [_c('book-summary-skelton')]], 2), _vm.isLoadingRecentBook || (_vm$recentBook = _vm.recentBook) !== null && _vm$recentBook !== void 0 && _vm$recentBook.summeryVideoUrl ? _c('div', {
    staticClass: "video"
  }, [_c('home-section-header', {
    attrs: {
      "title": "ملخصات زادي",
      "color": "#9ED8D3"
    }
  }), !_vm.isLoadingRecentBook ? [_c('book-video', {
    attrs: {
      "book": _vm.recentBook
    }
  })] : [_c('book-video-skelton')]], 2) : _vm._e(), _c('share-popup', {
    attrs: {
      "type": "BOOK",
      "entity": _vm.recentBook
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }