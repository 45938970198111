import { render, staticRenderFns } from "./BookSummaryDetails.vue?vue&type=template&id=027f5308&scoped=true&"
import script from "./BookSummaryDetails.vue?vue&type=script&lang=js&"
export * from "./BookSummaryDetails.vue?vue&type=script&lang=js&"
import style0 from "./BookSummaryDetails.vue?vue&type=style&index=0&id=027f5308&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "027f5308",
  null
  
)

export default component.exports