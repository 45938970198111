<template>
  <div class="share-popup">
    <div class="modal fade" :id="modalId" tabindex="-1" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title" style="font-weight: 600;">{{ modalTitle }}</p>
            <button type="button" class="close" @click="hideModal">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-show="!isShared" class="share-icons">
              <div v-for="network in networks" :key="network.name" :class="`share-${network.name}`">
                <ShareNetwork v-if="network.name != 'link'" :network="network.name" :url="shareContent.url || ''"
                  :title="shareContent.title" :description="shareContent.description" :hashtags="shareContent.hashtags"
                  @open="open(network.name)">
                  <i :class="network.icon"></i>
                </ShareNetwork>
                <div v-else @click="onCopyLink">
                  <i :class="network.icon"></i>
                </div>
              </div>
            </div>
            <div v-show="isShared" class="upload-share-screens">
              <div class="back-action" @click="handleStep">
                <img src="../../../../../public/assets/images/website/zadi/arrow.png" />
              </div>
              <div v-show="isShowedSample" class="sample">
                <img src="../../../../../public/assets//images/website/zadi/share-sample.png" />
              </div>
              <div v-show="!isShowedSample" class="upload">
                <div class="header">
                  <img src="../../../../../public/assets//images/website/zadi/share-passed.png" />
                  <p>تمت المشاركة بنجاح</p>
                  <p>الرجاء التقاط لقطة شاشة، للمنشور الذي قمت بمشاركته، ثم إرفاقها في هذا الحقل المخصص ليتم احتساب
                    النقاط
                    لك.</p>
                </div>
                <div class="content">
                  <image-uploader ref="imageUploader" @on-show-sample="isShowedSample = true"
                    @on-image-upload="imageLink = $event" />
                  <zadi-button title="إرسال" :loader="isShareLoading" :disabled="!imageLink"
                    @on-click="handleShareEntity" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShareEntityRepository from '../../repositories/share-entity';
import ImageUploader from '../../components/shared/ImageUploader';
import ZadiButton from '../../components/shared/ZadiButton';
import toasted from "@/app/shared/utilities/toasted";
import auth from '../../helpers/auth.js';

const ZadiType = {
  MAGAZINE: 'MAGAZINE',
  BOOK: 'BOOK',
  FACEBOOK: 'FACEBOOK_PAGE',
}

export default {
  components: {
    ZadiButton,
    ImageUploader
  },
  props: {
    type: {
      type: String,
      validator: function (value) {
        return Object.values(ZadiType).includes(value);
      },
      default: () => ZadiType.MAGAZINE,
    },
    entity: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isShared: false,
      isShowedSample: false,
      isShareLoading: false,
      imageLink: '',
      networks: [
        {
          name: 'facebook',
          icon: 'fa fa-facebook-square',
        },
        {
          name: 'whatsapp',
          icon: 'fa fa-whatsapp',
        },
        {
          name: 'telegram',
          icon: 'fa fa-telegram',
        },
        {
          name: 'link',
          icon: 'fa fa-link',
        }
      ]
    }
  },
  computed: {
    isAuth() {
      return auth.isAuthenticated;
    },
    modalTitle() {
      switch (this.type) {
        case ZadiType.BOOK:
          return `مشاركة المخلص ${this.entity?.title}`;
        case ZadiType.MAGAZINE:
          return `مشاركة المجلة العدد ${this.entity?.releaseNumber}`;
        case ZadiType.FACEBOOK:
          return `مشاركة صفحة مؤسسة التميز التنموية`;
      }
    },
    bookUrl() {
      const url = new URL(window.location.href);
      return `${url.origin}/#/zadi/books/${this.entity?.id}`;
    },
    modalId() {
      switch (this.type) {
        case ZadiType.BOOK:
          return `share-popup-book`;
        case ZadiType.MAGAZINE:
          return `share-popup-magazine`;
        case ZadiType.FACEBOOK:
          return `share-popup-facebook`;
      }
    },
    slicedDescription() {
      const sliced = this.entity?.description.slice(0, 300)
      return this.entity?.description.length > 300 ? `${sliced}..الخ` : sliced;
    },
    shareContent() {
      switch (this.type) {
        case ZadiType.BOOK:
          return {
            url: this.bookUrl,
            title: `ملخص ${this.entity?.title}`,
            description: this.slicedDescription,
            hashtags: "مجلة زادي ,ملخصات كتب ,مؤسسة التميز التنموية"
          }
        case ZadiType.MAGAZINE:
          return {
            url: this.entity?.link,
            title: `مجلة زادي العدد ${this.entity?.releaseNumber}`,
            description: "مجلة زادي مجلة ثقافية أسبوعية.. لنتزود قراءة ثم فكراً فحضارة",
            hashtags: "مجلة زادي ,ملخصات كتب ,مؤسسة التميز التنموية"
          }
        case ZadiType.FACEBOOK:
          return {
            url: this.entity?.link,
            title: `مؤسسة التميز التنموية`,
            description: "نموذج إنساني تنموي رائد",
            hashtags: "مؤسسة التميز التنموية, نموذج إنساني تنموي رائد"
          }
      }
    },
  },
  methods: {
    open() {
      if (this.isAuth) {
        this.isShared = true;
      }
    },
    hideModal() {
      $(`#${this.modalId}`).modal('hide');
      this.$refs.imageUploader.onRemoveFile();
      this.isShared = false;
    },
    handleStep() {
      if (this.isShared && !this.isShowedSample) {
        this.isShared = false;
        return
      }

      if (this.isShared && this.isShowedSample) {
        this.isShowedSample = false;
        return
      }
    },
    async handleShareEntity() {
      if (!this.imageLink) return;

      try {
        this.isShareLoading = true;
        await ShareEntityRepository.shareImage({
          imageLink: this.imageLink,
          entityId: this.type !== ZadiType.FACEBOOK ? this.entity.id : undefined,
          entityType: this.type,
        });

        toasted.success('تمت المشاركة بنجاح');

        this.isShareLoading = false;
        this.imageLink = '';

        this.hideModal();
      } finally {
        this.isShareLoading = false;
      }
    },
    async onCopyLink() {
      const link = this.type == ZadiType.BOOK ? this.bookUrl : this.entity?.link;
      navigator.clipboard.writeText(link);
      if (this.isAuth) {
        this.isShared = true;
      }
    },
  }
};
</script>

<style scoped>
.share-icons {
  padding: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px
}

.share-icons i {
  font-size: 45px;
  transition: all 0.2s linear;
}

.share-icons i:hover {
  transform: scale(1.05);
}

.share-icons .share-facebook i {
  color: #3b5998;
}

.share-icons .share-whatsapp i {
  color: #25d366;
}

.share-icons .share-telegram i {
  color: #0088cc;
  font-size: 40px;
}

.share-icons .share-link {
  cursor: pointer;
}

.share-icons .share-link i {
  color: #434a4e;
  font-size: 40px;
}

.upload-share-screens {
  position: relative;
}

.upload-share-screens .back-action {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.upload-share-screens .back-action img {
  width: 20px;
}

.upload-share-screens .upload .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  margin-bottom: 15px;
}

.upload-share-screens .upload .header img {
  max-width: 80px;
  width: 100%;
}

.upload-share-screens .upload .header p:nth-child(2) {
  color: #9DD8D4;
  font-weight: 700;
  text-align: center;
}

.upload-share-screens .upload .header p:nth-child(3) {
  color: #9A9A9A;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
}

.upload-share-screens .sample {
  display: flex;
  justify-content: center;
}

.upload-share-screens .sample img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}
</style>
