var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['header', _vm.isScrolled ? 'scrolled' : null]
  }, [_c('router-link', {
    attrs: {
      "to": {
        name: 'Home'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": "assets/images/logo/logo-svg.svg",
      "alt": "tamayoz-logo",
      "width": "32px"
    }
  })]), !_vm.isAuthPages ? _c('div', [!_vm.isAuth ? _c('router-link', {
    staticClass: "login-btn",
    attrs: {
      "to": {
        name: 'ZadiLogin'
      }
    }
  }, [_vm._v(" تسجيل الدخول ")]) : [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleOutside,
      expression: "handleOutside"
    }],
    staticClass: "zadi-dropdown"
  }, [_c('div', {
    staticClass: "wrapper",
    on: {
      "click": function ($event) {
        _vm.dropdownStatus = !_vm.dropdownStatus;
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.userName))]), _c('img', {
    attrs: {
      "src": require("../../../../public/assets/images/website/zadi/avatar.png")
    }
  }), _c('img', {
    attrs: {
      "src": require("../../../../public/assets/images/website/zadi/arrow-down.png")
    }
  })]), _c('div', {
    class: ['list', _vm.dropdownStatus ? 'active' : null],
    on: {
      "click": function ($event) {
        _vm.dropdownStatus = false;
      }
    }
  }, [_c('router-link', {
    staticClass: "item",
    attrs: {
      "to": {
        name: 'ZadiUserProfile'
      }
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../../public/assets/images/website/zadi/profile.png")
    }
  }), _c('span', [_vm._v("الملف الشخصي")])]), _c('div', {
    staticClass: "item",
    on: {
      "click": _vm.handleLogout
    }
  }, [_c('img', {
    attrs: {
      "src": require("../../../../public/assets/images/website/zadi/exit.png")
    }
  }), _c('span', [_vm._v("تسجيل الخروج")])])], 1)])], _c('global-search')], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }