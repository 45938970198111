<template>
  <div class="recent-book-section">
    <div class="summry">
      <home-section-header title="كنوز زادي" route-name="ZadiBooks" />
      <template v-if="!isLoadingRecentBook">
        <book-summary :recent-book="recentBook" @share-recent-book="onShareRecentBook" />
      </template>
      <template v-else>
        <book-summary-skelton />
      </template>
    </div>
    <div class="video" v-if="isLoadingRecentBook || recentBook?.summeryVideoUrl">
      <home-section-header title="ملخصات زادي" color="#9ED8D3" />
      <template v-if="!isLoadingRecentBook">
        <book-video :book="recentBook" />
      </template>
      <template v-else>
        <book-video-skelton />
      </template>
    </div>
    <share-popup type="BOOK" :entity="recentBook" />
  </div>
</template>

<script>
import HomeSectionHeader from "../shared/HomeSectionHeader.vue";
import BookSummary from "../books/BookSummary.vue";
import BookSummarySkelton from '../books/BookSummarySkelton.vue';
import BookVideo from "../books/BookVideo.vue";
import BookVideoSkelton from '../books/BookVideoSkelton.vue';
import SharePopup from "../shared/SharePopup.vue";
import booksRepository from "../../repositories/booksRepository";

export default {
  components: { BookSummary, BookSummarySkelton, BookVideo, BookVideoSkelton, SharePopup, HomeSectionHeader },
  data() {
    return {
      recentBook: null,
      isLoadingRecentBook: false,
    }
  },
  methods: {
    onShareRecentBook() {
      this.$nextTick(() => {
        $(`#share-popup-book`).modal('show');
      });
    }
  },
  async mounted() {
    this.isLoadingRecentBook = true;
    const res = await booksRepository.getRecentBook();
    this.recentBook = await res?.data?.data?.at(0);
    this.isLoadingRecentBook = false;
  }
};
</script>

<style scoped>
.recent-book-section {
  padding: 60px 0px 0px 0px;
}

.video {
  padding: 60px 0px 0px 0px;
}

@media (max-width: 560px) {
  .recent-book-section {
    padding: 60px 0px 0px 0px;
  }
}
</style>
