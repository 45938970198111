<template>
  <div :class="['header', isScrolled ? 'scrolled' : null]">
    <router-link :to="{ name: 'Home' }">
      <img src="assets/images/logo/logo-svg.svg" alt="tamayoz-logo" width="32px">
    </router-link>
    <div v-if="!isAuthPages">
      <router-link :to="{ name: 'ZadiLogin' }" class="login-btn" v-if="!isAuth">
        تسجيل الدخول
      </router-link>
      <template v-else>
        <div class="zadi-dropdown" v-click-outside="handleOutside">
          <div class="wrapper" @click="dropdownStatus = !dropdownStatus">
            <span>{{ userName }}</span>
            <img src="../../../../public/assets/images/website/zadi/avatar.png" />
            <img src="../../../../public/assets/images/website/zadi/arrow-down.png" />
          </div>
          <div :class="['list', dropdownStatus ? 'active' : null]" @click="dropdownStatus = false">
            <router-link :to="{ name: 'ZadiUserProfile' }" class="item">
              <img src="../../../../public/assets/images/website/zadi/profile.png" />
              <span>الملف الشخصي</span>
            </router-link>
            <div class="item" @click="handleLogout">
              <img src="../../../../public/assets/images/website/zadi/exit.png" />
              <span>تسجيل الخروج</span>
            </div>
          </div>
        </div>
      </template>
      <global-search />
    </div>
  </div>
</template>

<script>
import auth from '../helpers/auth.js';
import GlobalSearch from '@/app/website/website-components/global-search/components/global-search.vue';

export default {
  name: 'ZadiHeader',
  components: { GlobalSearch },
  data() {
    return {
      isScrolled: false,
      dropdownStatus: false
    }
  },
  methods: {
    handleOutside() {
      this.dropdownStatus = false;
    },
    handleLogout() {
      auth.logout();
    },
    handleScroll() {
      const currentScroll = window.scrollY;
      this.isScrolled = currentScroll > 40;
      this.dropdownStatus = false;
    },
  },
  computed: {
    isAuthPages() {
      return ['ZadiLogin', 'ZadiResetPassword'].includes(this.$route.name);
    },
    userName() {
      return auth.user?.name;
    },
    isAuth() {
      return auth.isAuthenticated;
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  }
}
</script>

<style scoped>
.header {
  height: 71px;
  width: 100%;
  padding: 7px 40px 7px 40px;
  background: rgb(255, 255, 255);
  position: sticky;
  top: 0px;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 15px;
  transition: all 0.2s ease-in-out;
}

.header.scrolled {
  box-shadow: 0px 13px 35px -12px rgba(35, 35, 35, 0.15);
}

.header>div {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
}

.header>div ::v-deep .search-icon {
  background: #F4ACBF;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.login-btn {
  background: #9DD7D3;
  height: 43px;
  width: 170px;
  font-weight: 500;
  border-radius: 40px;
  color: white !important;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  box-shadow: 3px 9px 8px rgba(106, 106, 106, 16%);
}

.login-btn:hover {
  background: #6ed6cf;
  transform: scale(1.02);
}

.zadi-dropdown {
  position: relative;
}

.wrapper {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}

.wrapper span {
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 140px;
  display: inline-block;
}

.wrapper img:nth-child(2) {
  width: 39px;
  filter: drop-shadow(3px 9px 8px rgba(106, 106, 106, 16%));
}

.list {
  position: absolute;
  top: calc(100% + 10px);
  left: 0px;
  background: white;
  color: rgb(0, 0, 0);
  border-radius: 8px;
  box-shadow: unset;
  transform-origin: top;
  transition: all 0.2s ease-in-out;
  transform: scaleY(0);
  padding: 4px 0px;
  width: 150px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  overflow: hidden;
}

.list.active {
  box-shadow: 0px 12px 35px 14px rgba(35, 35, 35, 0.15);
  transform-origin: top;
  transform: scaleY(1);
}

.list .item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 6px;
  font-size: 14px;
  font-weight: 500;
  padding: 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: unset;
  color: unset
}

.list .item:hover {
  background: #F4ACBF;
  color: white;
}

.list .item img {
  width: 23px;
}

@media (max-width: 560px) {
  .header {
    padding: 8px 20px 8px 20px;
  }

  .login-btn {
    background: #9DD7D3;
    height: 40px;
    width: 130px;
    font-size: 14px;
  }
}
</style>
