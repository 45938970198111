var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "zadi-magazines-page"
  }, [_c('page-header', {
    attrs: {
      "title": "أعداد مجلة زادي"
    }
  }), _c('infinite-scroll-wrapper', {
    attrs: {
      "limit": _vm.limit,
      "total": _vm.total,
      "is-loading": _vm.isLoadingMagazines
    },
    on: {
      "fetch-data": _vm.fetchAllMagazines
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._l(_vm.magazines, function (magazine) {
    return _c('div', {
      key: magazine.id,
      staticClass: "col-lg-3 col-md-4 col-sm-6 col-12"
    }, [_c('magazine-card', {
      attrs: {
        "magazine": magazine
      },
      on: {
        "share-magazine": _vm.onShareMagazine
      }
    })], 1);
  }), _vm.isLoadingMagazines ? _vm._l(_vm.limit, function (n) {
    return _c('div', {
      key: n,
      staticClass: "col-lg-3 col-md-4 col-sm-6 col-12"
    }, [_c('magazine-card-skelton')], 1);
  }) : _vm._e()], 2)]), _c('share-popup', {
    attrs: {
      "type": "MAGAZINE",
      "entity": _vm.magazine
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }