<template>
  <div class="facebook-share-section">
    <div class="row">
      <div class="col-lg-6">
        <div class="content">
          <p>شارك صفحة مؤسسة التميز التنموية
            واحصل على العديد من النقاط</p>
          <button @click="onShareFacebook">مشاركة الصفحة</button>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="image">
          <img src="../../../../../public/assets/images/website/zadi/facebook-bg.png">
        </div>
      </div>
    </div>
    <share-popup type="FACEBOOK_PAGE" :entity="entity" />
  </div>
</template>

<script>
import SharePopup from "../shared/SharePopup.vue";

export default {
  components: { SharePopup },
  data() {
    return {
      entity: {
        link: 'https://www.facebook.com/Tyateem',
      }
    }
  },
  methods: {
    onShareFacebook() {
      this.$nextTick(() => {
        $(`#share-popup-facebook`).modal('show');
      });
    },
  },
};
</script>

<style scoped>
.facebook-share-section {
  margin: 60px 0px 0px 0px;
  background: #6F9FE9;
  border-radius: 25px;
  min-height: 200px;
  overflow: hidden;
}

.image {
  padding: 15px 22px 0px 22px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.image img {
  max-width: 400px;
  width: 100%;
  object-fit: contain;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 22px 22px;
}

.content p {
  font-weight: bold;
  color: white;
  font-size: 1.8rem;
  line-height: 40px;
}

.content button {
  margin-top: 20px;
  color: #f4acbf;
  background: white;
  height: 50px;
  border-radius: 35px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  padding: 15px 25px;
}

.content button:hover {
  transform: scale(1.02);
}

@media screen and (max-width: 600px) {
  .content p {
    font-size: 1.3rem;
    line-height: 35px;
  }

  .image img {
    max-width: 350px;
  }
}
</style>