var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "zadi-home-page"
  }, [_c('hero-section'), _c('div', {
    staticClass: "wrapper"
  }, [_c('recent-magazines-section'), _c('facebook-share-section'), _c('recent-book-section')], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }